<template>
  <div>
    <page-header has-back></page-header>
    <div class="layout-main-body">
      <el-card shadow="never">
        <el-tabs v-model="activeTabName">
          <el-tab-pane label="对公汇款" name="1">
            <div class="el-form-item el-form-item--small">
              <label class="el-form-item__label">充值帐户</label>
              <div class="el-form-item__content"><span>{{ currentAccount.name }}</span></div>
            </div>
            <div class="el-form-item el-form-item--small">
              <label class="el-form-item__label">帐户余额</label>
              <div class="el-form-item__content"><span class="money">{{ currentAccount.balance }}元</span></div>
            </div>
            <div class="el-form-item el-form-item--small">
              <label class="el-form-item__label">汇款流程</label>
              <div class="el-form-item__content" style="line-height: 0; margin-top: 6px">
                <el-steps direction="vertical" :active="2" finish-status="process">
                  <el-step title="使用银行卡汇款至素客通收款账号">
                    <div class="bank-info" slot="description">
                      <div class="el-form-item el-form-item--small bank-item">
                        <label class="el-form-item__label color-grey">收款户名</label>
                        <div class="el-form-item__content">厦门素客通软件有限公司</div>
                      </div>
                      <div class="el-form-item el-form-item--small bank-item">
                        <label class="el-form-item__label color-grey">收款银行</label>
                        <div class="el-form-item__content">招商银行股份有限公司厦门江头支行</div>
                      </div>
                      <div class="el-form-item el-form-item--small bank-item">
                        <label class="el-form-item__label color-grey">收款账号</label>
                        <div class="el-form-item__content">592907666110101</div>
                      </div>
                    </div>
                  </el-step>
                  <el-step title="填写汇款信息">
                    <div slot="description" style="margin-top: 20px">
                      <el-form :model="bankForm" :rules="bankFormRules" ref="bankForm" label-width="120px"
                               @submit.native.prevent style="width: 400px">
                        <el-form-item label="汇款金额" prop="amount">
                          <el-input v-model.number="bankForm.amount"></el-input>
                        </el-form-item>
                        <el-form-item label="汇款银行账号" prop="accountCardNumber">
                          <el-input v-model="bankForm.accountCardNumber"></el-input>
                          <el-popover
                              placement="right"
                              width="400"
                              v-model="bankPopoverShow"
                              trigger="click">
                            <el-table :data="bankList" @row-click="handleBankSelected">
                              <el-table-column prop="bankCardOwner" label="户名"></el-table-column>
                              <el-table-column prop="cardNumber" label="卡号"></el-table-column>
                            </el-table>
                            <el-button slot="reference" type="text">选择银行卡</el-button>
                          </el-popover>
                        </el-form-item>
                        <el-form-item label="汇款户名" prop="accountName">
                          <el-input v-model="bankForm.accountName"></el-input>
                        </el-form-item>
                        <el-form-item label="汇款时间" prop="payTime">
                          <el-date-picker
                              v-model="bankForm.payTime"
                              type="date"
                              unlink-panels
                              style="width: 100%;"
                              :picker-options="pickerOption"
                              value-format="yyyy-MM-dd"
                          ></el-date-picker>
                        </el-form-item>
                        <el-form-item>
                          <el-button type="primary" @click="handleSubmitBankOrder" :loading="bankLoading">提交汇款信息
                          </el-button>
                        </el-form-item>
                      </el-form>
                    </div>
                  </el-step>
                  <el-step title="等待2 ~ 24小时后汇款金额充值到账"
                           description="汇款户名、银行信息正确的情况下1天内可到账，最快2小时，请耐心等待。我们将通过邮件、站内信的方式向您反馈充值结果，也可以在我的订单查看充值进度。">
                  </el-step>
                </el-steps>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="在线充值" name="2">
            <el-form :model="onlinePayForm" :rules="onlinePayRules" ref="onlinePayForm" label-width="80px">
              <el-form-item label="充值帐户">
                <span>{{ currentAccount.name }}</span>
              </el-form-item>
              <el-form-item label="帐户余额">
                <span class="money">{{ currentAccount.balance }}元</span>
              </el-form-item>
              <el-form-item label="充值金额" prop="amount">
                <div>
                  <el-input v-model.number="onlinePayForm.amount" clearable class="input-balance"></el-input>
                  元
                </div>
                <div>
                  <el-popover
                      placement="right"
                      width="300"
                      v-model="calcShow"
                      trigger="click">
                    <div class="popover-body">
                      <el-input v-model.number="calcCount" clearable placeholder="请输入VIP年卡数量">
                        <el-button slot="append" @click.native.stop="handleCalcMoney" :loading="calcLoading">确定
                        </el-button>
                      </el-input>
                    </div>
                    <el-button type="text" slot="reference">金额计算器</el-button>
                  </el-popover>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="handleOnlinePay" :loading="onlineSubmitLoading">立即充值</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
  </div>
</template>

<script>

import { AccountApi, AccountBankCardApi, AccountGoodsApi, AccountOrderApi } from '@/api'
import Dictionary from '@/utils/dictionary'
import moment from 'moment'
import { moneyValidator } from '@/utils/validator'

export default {
  name: 'BalanceRecharge',
  data () {
    return {
      currentAccount: {},
      activeTabName: '1',
      onlinePayForm: {
        amount: ''
      },
      onlinePayRules: {
        amount: [
          {
            type: 'number',
            pattern: /(^[1-9]\d*$)/,
            message: '请输入正确的金额，只能输入正整数',
            trigger: 'blur'
          }
        ]
      },
      calcCount: '',
      calcLoading: false,
      calcShow: false,
      bankForm: {
        amount: '',
        accountCardNumber: '',
        accountName: '',
        payTime: '',
        orderDesc: '充值金额-对公汇款',
        orderType: Dictionary.amsOrderType.remittance.code,
        payWay: 3
      },
      bankFormRules: {
        amount: [
          {
            required: true,
            message: '请输入汇款金额',
            trigger: 'blur'
          },
          {
            trigger: 'blur',
            validator: moneyValidator
          },
          {
            type: 'number',
            message: '请输入正确的金额'
          }
        ],
        accountCardNumber: [
          {
            required: true,
            message: '请输入汇款银行账号',
            trigger: 'blur'
          }
        ],
        accountName: [
          {
            required: true,
            message: '请输入汇款户名',
            trigger: 'blur'
          }
        ],
        payTime: [
          {
            required: true,
            message: '请输入汇款时间',
            trigger: 'blur'
          }
        ]
      },
      bankLoading: false,
      bankList: [],
      bankPopoverShow: false,
      onlineSubmitLoading: false,
      pickerOption: {
        disabledDate (time) {
          // return time.getTime() > Date.now() - 8.64e7
          return time.getTime() > moment().toDate()
        }
      }
    }
  },
  methods: {
    async initData () {
      this.currentAccount = await AccountApi.getAccount()
      const bankCardRes = await AccountBankCardApi.getList({
        page: 0,
        size: 50
      })
      this.bankList = bankCardRes.rows
      const bankDefault = this.bankList.find(it => it.isDefault)
      if (bankDefault) {
        this.bankForm.accountCardNumber = bankDefault.cardNumber
        this.bankForm.accountName = bankDefault.bankCardOwner
      }
    },
    handleCalcMoney () {
      this.calcLoading = true
      AccountGoodsApi.getGoodsList([{
        vipCardCount: this.calcCount
      }]).then(res => {
        this.calcLoading = false
        this.onlinePayForm.amount = Number(res[0].amount * 100 / 100)
        this.calcShow = false
      }).catch(() => {
        this.calcLoading = false
      })
    },
    handleBankSelected (row) {
      this.bankForm.accountCardNumber = row.cardNumber
      this.bankForm.accountName = row.bankCardOwner
      this.bankPopoverShow = false
    },
    /**
     * 提交对公汇款订单
     */
    handleSubmitBankOrder () {
      this.$refs.bankForm.validate((valid) => {
        if (valid) {
          this.bankLoading = true
          const data = { ...this.bankForm }
          data.payTime = `${data.payTime} 00:00:00`
          data.orderRemark = `充值金额-对公汇款-${data.amount}元`
          AccountOrderApi.createOrder(data).then(() => {
            this.bankLoading = false
            this.$router.push('/account/order/pay/success?source=1')
          }).catch(() => {
            this.bankLoading = false
          })
        } else {
          return false
        }
      })
    },
    /**
     * 提交在线支付订单
     */
    handleOnlinePay () {
      this.onlineSubmitLoading = true
      AccountOrderApi.createOrder({
        amount: this.onlinePayForm.amount,
        orderType: Dictionary.amsOrderType.rechargeMoney.code,
        orderDesc: '充值金额',
        orderRemark: `充值金额-在线支付-${this.onlinePayForm.amount}元`
      }).then((res) => {
        this.onlineSubmitLoading = false
        this.$router.replace(`/account/order/pay?id=${res.id}`)
      }).catch(() => {
        this.onlineSubmitLoading = false
      })
    }
  },
  created: function () {
    this.initData()
  }
}
</script>

<style scoped lang="less">
.input-balance {
  width: 200px;
}

.money {
  font-weight: 700;
}

.popover-body {
  padding: 20px;
}

.popover-money {
  margin-top: 10px;
}

.bank-info {
  width: 600px;
  background-color: #f3f4f7;
  padding: 10px;
  margin: 10px 0;
  font-size: 12px;
}

.el-form-item__label {
  width: 80px;
}

.el-form-item__content {
  margin-left: 80px;
}

.bank-item {
  margin-bottom: 0;
}

</style>
